import React, { useEffect } from 'react'
import { NextPage } from 'next'
import {
  Heading,
  Text,
  Stack,
  Alert,
  LinkButton,
  useBackendMutation,
  useBackendQuery,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
} from '@thirstycamel/ui'
import LayoutMain from '../layout/LayoutMain/LayoutMain'
import { NotificationNumber } from '../components/NotificationNumber/NotificationNumber'
import { useUser } from '../hooks/useUser'
import { useAuthHeaders } from '../hooks/useAuthHeaders'
import { useRouter } from 'next/router'
import { backend } from '../utils/backend'

const Index: NextPage = () => {
  const { isStoreUser, user, token } = useUser()

  const authHeaders = useAuthHeaders()

  const router = useRouter()
  const { pushToken } = router.query

  useEffect(() => {
    if (pushToken)
      backend('stores/push-token', { method: 'POST', data: { pushToken }, ...authHeaders })
  }, [pushToken])

  return (
    <LayoutMain hideMenuButton>
      <Stack py={10} px={5} height="100%">
        <Stack spacing={3}>
          <Heading>Thirsty Camel</Heading>
          <Text>Welcome to the Store Portal!</Text>
        </Stack>

        {isStoreUser && (
          <Stack spacing={8}>
            {!user?.store?.isPaymentsReady && (
              <Alert status="error" variant="left-accent" pb={5}>
                <Stack spacing={5} align="flex-start">
                  <Stack spacing={3}>
                    <Heading>PAYMENT DETAILS REQUIRED</Heading>

                    <Stack>
                      <Text>Your payment details are missing.</Text>
                      <Text>Please click the link below to fill out your details.</Text>
                    </Stack>
                  </Stack>

                  <LinkButton
                    href={`/stores/${user?.store?.slug}/payments`}
                    variantColor="pink"
                    isRound
                  >
                    Enter Payment Details
                  </LinkButton>
                </Stack>
              </Alert>
            )}
          </Stack>
        )}

        {isStoreUser && <NotificationNumber />}
      </Stack>
    </LayoutMain>
  )
}

export default Index
