import {
  Button,
  FormLabel,
  Stack,
  Text,
  TextInput,
  useBackendMutation,
  useForm,
  useToast,
} from '@thirstycamel/ui'
import { NewPasswordDto } from '@ts/core/src/modules/auth/dto/NewPasswordDto'
import { SetNotifications } from '@ts/core/src/modules/store/store.object'
import React, { useEffect, useState } from 'react'
import { useStore } from '../../store/hooks'
import FormWrapper from '../FormWrapper'
import Input from '../Input'

export const REGEX_MOBILE_NUMBER = /^04\d{8}$/
export const REGEX_MULTIPLE_MOBILE_NUMBER = /^(04\d{8}(,)?)+$/

export const NotificationNumber: React.FC = ({ ...restProps }) => {
  const storeId = useStore(s => s.auth.user?.store?.id)
  const notificationsPhone = useStore(s => s.auth.user?.store?.notificationsPhone)
  const notificationEmails = useStore(s => s.auth.user?.store?.notificationEmails)
  const form = useForm<SetNotifications>({
    defaultValues: {
      notificationsPhone,
      notificationEmails,
    },
  })

  const toast = useToast()
  const [set, { isLoading }] = useBackendMutation<void, SetNotifications>(
    [`/stores/${storeId}/notifications-number`, { method: 'PATCH' }],
    {
      onSuccess: () =>
        toast({
          title: 'Notifications number updated!',
          status: 'success',
          isClosable: true,
        }),
      onError: () =>
        toast({
          title: 'There was an error updating your notifications number.',
          status: 'error',
          isClosable: true,
        }),
    },
  )

  const handleSubmit = form.handleSubmit(async values => {
    await set({
      notificationsPhone: values.notificationsPhone.filter(v => v),
      notificationEmails: values.notificationEmails.filter(v => v),
    })
  })

  return (
    <FormWrapper {...restProps} form={form} onSubmit={handleSubmit} maxW={500} mt="auto" ml="auto">
      <FormLabel textTransform="initial">Order Notifications Email</FormLabel>
      <Stack spacing={5}>
        {Array.from({ length: 5 }).map((_, index) => {
          return (
            <Input
              as={TextInput}
              name={`notificationEmails[${index}]`}
              type="email"
              isReadOnly={isLoading}
              mr={[, 4, 0, 4]}
              mb={[4, 0, 4, 0]}
            />
          )
        })}
        <FormLabel textTransform="initial">Order Notifications SMS</FormLabel>
        {Array.from({ length: 3 }).map((_, index) => {
          return (
            <Input
              as={TextInput}
              placeholder="e.g. 0401 234 567"
              name={`notificationsPhone[${index}]`}
              type="tel"
              isReadOnly={isLoading}
              mr={[, 4, 0, 4]}
              mb={[4, 0, 4, 0]}
              rules={{
                validate: {
                  auPhoneNumber: (value: string) => {
                    if (value) {
                      const success = REGEX_MOBILE_NUMBER.test(value)
                      return success
                    }
                  },
                },
              }}
            />
          )
        })}
        <Button type="submit" size="sm" variantColor="pink" isLoading={isLoading}>
          SAVE
        </Button>
      </Stack>
    </FormWrapper>
  )
}
